import { PlusIcon } from "@/assets/svgs";
import { ISnapshotTemplate } from "@/types";
import { useEffect, useState } from "react";
import AddNewSnapshots from "../SnapshotsModal";
import { UseFormReturn } from "react-hook-form";
import { CreateCarFormValues } from "@/validations/create-car.schema";
import { persistNewSnapshots } from "@/pages/applications/car-form/helper";

interface IFormSnapshot {
  methods: UseFormReturn<CreateCarFormValues, any>;
}

interface IGetSnapshots {
  name: string;
  type: string;
  url: string;
}

export default function FormSnapshots({ methods }: IFormSnapshot) {
  const [openNewSnapshot, setOpenSnapshot] = useState<boolean>(false);
  const [newSnapshots, setNewSnapshots] = useState<ISnapshotTemplate[]>(() => {
    const savedSnapshots = sessionStorage.getItem("snapshotsNew");
    return savedSnapshots ? JSON.parse(savedSnapshots) : [];
  });

  const getSnapshots = methods.getValues("snapshots") as IGetSnapshots[];

  useEffect(() => {
    persistNewSnapshots(newSnapshots);
  }, [newSnapshots]);

  const handleDeleteSnapshot = (name: string) => {
    setNewSnapshots((prevSubItems) => {
      const prevList = prevSubItems.filter((item) => item.name !== name);
      persistNewSnapshots(prevList);
      return prevList;
    });
    const list = getSnapshots.filter((item) => item.name !== name);
    methods.setValue("snapshots", list);
  };

  return (
    <>
      {newSnapshots?.map((subItem) => (
        <div key={subItem.name}>
          <p className="text-[#30345E] text-sm font-semibold mb-2">
            {subItem.label}
          </p>
          <div key={subItem.name} className="relative">
            <div className="mb-2 text-center flex items-center justify-center bg-[#EAEAEA] w-full h-32 overflow-hidden rounded-lg">
              <img
                src={subItem.template}
                alt={subItem.name}
                width={100}
                className="w-full max-w-full object-cover"
              />
            </div>
            <div className="flex items-center gap-1">
              <button
                aria-label="Delete"
                type="button"
                className="absolute -top-2 -right-2 flex items-center justify-center text-sm bg-[#ff4d4f] text-white border border-white rounded-full w-6 h-6"
                onClick={() => handleDeleteSnapshot(subItem.name)}
              >
                &#x2715;
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="w-full h-36 flex items-center justify-center mt-5">
        <button
          onClick={() => setOpenSnapshot(true)}
          className="flex items-center gap-2 text-sm text-[#30345E] font-semibold"
        >
          <PlusIcon /> Add More Images
        </button>
      </div>

      <AddNewSnapshots
        isModalOpen={openNewSnapshot}
        handleOk={() => setOpenSnapshot(false)}
        handleCancel={() => setOpenSnapshot(false)}
        setNewSnapshots={setNewSnapshots}
        methods={methods}
        getSnapshots={getSnapshots}
      />
    </>
  );
}
