import React, { useState, useEffect } from "react";
import storage from "@l/storage";
import {
  AppTabContainer,
  ApplicationLayout,
  CustomSegmentButton,
} from "@/layouts/application-layouts/styles";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import { Tabs, Segmented } from "antd";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { useTranslation } from "react-i18next";
import { carSearchById, getCarList } from "@/hooks/use-loan";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import AvailableCarList from "./AvailableCarList";
import InventoryFilterModal from "@/layouts/application-layouts/InventoryFilterModal";
import TableFieldFilters from "./TableFieldFilters";
import { ITabFilterMap, IVariant, IViewMap } from "@/types";

type IProps = {
  variant: IVariant;
};

const Inventory = ({ variant }: IProps) => {
  const role = storage.getRole();
  const user = storage.getUser();
  const country = storage.getCountry();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const [carsResult, setCarsResult] = useState<any>([]);
  const [carId, setCarId]: any = useState("");
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isFranchise = storage.utilities.isFranchise();
  const franchises = storage.getFranchises();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanId = queryParameters.get("loan_id") as string;
  const { t } = useTranslation("inventory");
  const carVin = queryParameters.get("car_id_vin") as string;
  const from = queryParameters.get("from");
  const to = queryParameters.get("to");
  const carMakeId = queryParameters.get("make_id");
  const carModelId = queryParameters.get("model_id");
  const franchiseId = queryParameters.get("franchise_id");
  const franchise = queryParameters.get("franchise");
  const franchiseList = queryParameters.get("franchise_list");
  const pageNumber = queryParameters.get("page_number") ?? 1;
  const pageSize = queryParameters.get("page_size");
  const searchQuery = queryParameters.get("query") as string;
  const fromDate = queryParameters.get("from_date") as string;
  const to_date = queryParameters.get("to_date") as string;
  const status = queryParameters.get("status") as string;
  const simulate = queryParameters.get("simulate") as string;
  const partnerId = queryParameters.get("partner_id") as string;
  const { hash } = useLocation();

  //states
  const [productData, setProductData]: any = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [tabValue, setTabValue] = useState("ready_for_listing");
  const [viewType, setViewType] = useState<string>("GRID");
  const [openInventoryFilter, setOpenInventoryFilter] =
    useState<boolean>(false);
  const [delistedValue, setDelistedValue] = useState<string | number>(
    "delisted_by_me"
  );

  useEffect(() => {
    document.title = `Dealer Plus | Inventory [${role}]`;
  }, [role]);

  useEffect(() => {
    const viewMap: IViewMap = {
      "#GRID": "GRID",
      "#LIST": "LIST",
    };

    const tabFilterMap: ITabFilterMap = {
      "#ready_for_listing": "ready_for_listing",
      "#under_moderation": "under_moderation",
      "#listed": "listed",
      "#failed_moderations": "failed_moderations",
      "#delisted": "delisted",
    };

    if (viewMap[hash]) {
      setViewType(viewMap[hash]);
    }

    if (tabFilterMap[hash]) {
      setTabValue(tabFilterMap[hash]);
    }
  }, [hash]);

  const tabFilter = () => {
    let carFilter = {};
    switch (tabValue) {
      case "ready_for_listing":
        carFilter = {
          moderated: true,
          moderation_status: "approved",
          marketplace_visible: false,
        };
        break;
      case "under_moderation":
        carFilter = { moderated: true, moderation_status: "pending" };
        break;
      case "listed":
        carFilter = {
          moderated: true,
          moderation_status: "approved",
          marketplace_visible: true,
        };
        break;
      case "failed_moderations":
        carFilter = { moderated: true, moderation_status: "rejected" };
        break;
      case "delisted": {
        if (delistedValue === "delisted_by_me") {
          carFilter = {
            moderated: true,
            marketplace_visible: false,
            delisted_by_me: true,
          };
        } else {
          carFilter = {
            moderated: true,
            marketplace_visible: false,
            delisted_by_me: false,
          };
        }
        break;
      }
      default:
        break;
    }
    return carFilter;
  };

  const getFranchiseParams = () => {
    if (franchiseId) return { franchise_id: franchiseId };
    if (franchise) return { franchise_id: franchise };
    if (franchiseList) return { franchise_id: franchiseList };
    return {};
  };

  const carSearch = async () => {
    const franchiseParams = getFranchiseParams();

    const carSearchParams = {
      country: country,
      ...(carId && {
        query: carId,
        ...franchiseParams,
      }),
      ...(!carId && {
        ...(from && { year_low: from }),
        ...(to && { year_high: to }),
        ...(carMakeId && { make_id: carMakeId }),
        ...(carModelId && { model_id: carModelId }),
        ...franchiseParams,
        ...(pageNumber && { page_number: pageNumber || "" }),
        ...(pageSize && { page_size: pageSize }),
        ...(carVin && { query: carVin }),
        ...(searchQuery && { query: searchQuery }),
        ...(fromDate && { from_date: fromDate }),
        ...(to_date && { to_date: to_date }),
        ...(status && { status: status }),
      }),
      ...query,
    };

    const data = await carSearchById(carSearchParams);
    return data;
  };

  const changeCarResults = async () => {
    const carListParams: any = {
      ...(searchQuery && { query: searchQuery }),
      country: country,
      exclude_category_name: "logbook",
      page_size: pageSize ?? 12,
      ...(pageNumber && { page_number: pageNumber }),
      ...(fromDate && { from_date: fromDate }),
      ...(to_date && { to_date: to_date }),
      ...(franchiseId && { franchise_id: franchiseId }),
      ...(status && { status: status }),
    };

    const data = await getCarList(carListParams);
    return data;
  };

  useQuery({
    queryKey: ["fetch_category_id"],
    queryFn: () => {
      const params = new URLSearchParams({
        search: "repo",
      });
      return client.get(`/v1/inventory/category`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filterCategoryId = parsedResults?.categoryList?.find(
        (item: any) => item?.name === "Repossessed Sale"
      )?.id;
      setCategoryId(filterCategoryId);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const isDealerDSAFranchisesToString = () => {
    if (isDealerDSA) {
      return franchises
        ?.map((franchise: Franchise) => {
          return franchise?.id;
        })
        ?.toString();
    }
    if (!isDealerDSA) {
      return "";
    }
  };

  const { isFetching: inventoryLoading } = useQuery({
    queryKey: [
      "fetch_inventory_cars",
      query,
      categoryId,
      tabValue,
      delistedValue,
    ],
    queryFn: async () => {
      if (variant === "select-car" || variant === "loan-transfer") {
        return carSearch();
      } else if (variant === "change-car") {
        return changeCarResults();
      } else if (variant === "repo") {
        const params = new URLSearchParams({
          country: country,
          category_id: categoryId,
          page_size: "12",
          ...query,
          ...queryParameters,
        });
        return client.get(`/v1/inventory/car`, { params });
      } else {
        const params = new URLSearchParams({
          country: country,
          franchise_id: isFranchise
            ? user?.company?.id
            : isDealerDSAFranchisesToString(),
          add_inspection_status: "true",
          exclude_category_name: "logbook",
          page_size: "12",
          created_by: isAccountManager || isDealerDSA ? user?.id : "",
          include_created_by: !!(isAccountManager || isDealerDSA),
          ...query,
          ...queryParameters,
          ...(tabFilter() && isFranchise ? tabFilter() : {}),
        });
        return client.get(`/v1/inventory/car`, { params });
      }
    },
    onSuccess: (res: any) => {
      const parsedCars = JSON.parse(res?.data);
      setCarsResult(parsedCars);
    },
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["car_data", country, loanId],
    queryFn: () => {
      if (loanId) return client.get(`/v1/origination/loans/${loanId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setProductData(parsedLoanDetails?.version);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleTabChange = (key: string) => {
    navigate({ hash: `${key}` });
    setTabValue(key);
  };

  let currentTitle;

  if (variant === "inventory") {
    currentTitle = isMobile ? t("inventory") : t("my-inventory");
  } else if (variant === "repo") {
    currentTitle = <span>All deals</span>;
  } else {
    currentTitle = t("discover-cars-within-your-budget");
  }

  const title = currentTitle;

  const renderInventoryComponent = () => {
    return (
      <AvailableCarList
        carsResult={carsResult}
        inventoryLoading={inventoryLoading}
        viewType={viewType}
        variant={variant}
        loanId={loanId}
        partnerId={partnerId}
        tabValue={tabValue}
        productData={productData}
        simulate={simulate}
        query={query}
        carId={carId}
        setCarId={setCarId}
      />
    );
  };

  const viewTypeOptions = [
    {
      value: "LIST",
      icon: (
        <ListIcon
          className={viewType === "LIST" ? "text-[#30345E]" : "text-[#7F83A8]"}
        />
      ),
    },
    {
      value: "GRID",
      icon: (
        <GridViewIcon
          className={viewType === "GRID" ? "text-[#30345E]" : "text-[#7F83A8]"}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-base xl:text-xl font-semibold text-[#000]">
          {title}
        </h2>
        {isFranchise && variant === "inventory" && (
          <div className="hidden xl:block">
            <Segmented
              options={viewTypeOptions}
              size="large"
              onChange={(value: any) => {
                navigate({ hash: `${value}` });
                setViewType(value);
              }}
              value={viewType}
            />
          </div>
        )}
      </div>

      <ApplicationLayout style={{ paddingTop: "1rem" }}>
        <TableFieldFilters
          variant={variant}
          inventoryLoading={inventoryLoading}
          query={query}
          setOpenInventoryFilter={setOpenInventoryFilter}
        />

        {isFranchise && variant === "inventory" ? (
          <div className="my-3">
            <AppTabContainer style={{ paddingTop: "0rem" }}>
              <Tabs
                activeKey={tabValue}
                items={[
                  {
                    key: "ready_for_listing",
                    label: "Ready for listing",
                    children: renderInventoryComponent(),
                  },
                  {
                    key: "under_moderation",
                    label: "Under moderation",
                    children: renderInventoryComponent(),
                  },
                  {
                    key: "listed",
                    label: "Listed",
                    children: renderInventoryComponent(),
                  },
                  {
                    key: "failed_moderations",
                    label: "Failed moderations",
                    children: renderInventoryComponent(),
                  },
                  {
                    key: "delisted",
                    label: "Delisted",
                    children: (
                      <div className="">
                        <CustomSegmentButton className="px-4 mb-1">
                          <Segmented
                            value={delistedValue}
                            onChange={setDelistedValue}
                            className="!w-full md:!w-[35%]"
                            options={[
                              {
                                label: (
                                  <div className="p-1">
                                    {t("Delisted by me")}
                                  </div>
                                ),
                                value: "delisted_by_me",
                              },
                              {
                                label: (
                                  <div className="p-1">
                                    {t("Delisted by autochek")}
                                  </div>
                                ),
                                value: "delisted_by_autochek",
                              },
                            ]}
                          />
                        </CustomSegmentButton>
                        {renderInventoryComponent()}
                      </div>
                    ),
                  },
                ]}
                onChange={handleTabChange}
              />
            </AppTabContainer>
          </div>
        ) : (
          renderInventoryComponent()
        )}
      </ApplicationLayout>
      <NewLoansBtn />

      <InventoryFilterModal
        isModalOpen={openInventoryFilter}
        handleOk={() => setOpenInventoryFilter(false)}
        handleCancel={() => setOpenInventoryFilter(false)}
        query={query}
        loading={inventoryLoading}
        variant={variant}
        setOpenFilter={setOpenInventoryFilter}
      />
    </React.Fragment>
  );
};

export default Inventory;
