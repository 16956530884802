import { useUploadImage } from "@/hooks/use-fetchers";
import { Loader } from "@/pages/auth/styles/loader";
import { CreateCarFormValues } from "@/validations/create-car.schema";
import React, { useRef } from "react";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";

const FormInputImage: React.FC<FormInputProps> = ({
  item,
  methods,
  group: _group,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutate: uploadImage, isLoading: isUploading } = useUploadImage();

  let url: string | undefined;

  const group = _group as keyof CreateCarFormValues;

  const getValues = (group: keyof CreateCarFormValues) =>
    methods.getValues(group) as { name: string; type: string; url: string }[];

  if (group) {
    const list = getValues(group);
    const target = list.find((e: any) => e.name === item.name);
    url = target?.url;
  }

  if (!group) {
    switch (item.name) {
      case "carThumbnail":
        url = methods.getValues("imageUrl");
        break;
      default:
        break;
    }
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    uploadImage(file, {
      onSuccess: (fileUrl) => {
        if (group) {
          const list = getValues(group);
          const target = list.find((e: any) => e.name === item.name);
          if (target) {
            target.url = fileUrl;
            methods.setValue(group, list);
          }

          if (!target) {
            list.push({ name: item.name, type: "image", url: fileUrl });
            methods.setValue(group, list);
          }
        }

        if (!group) {
          switch (item.name) {
            case "carThumbnail":
              methods.setValue("imageUrl", fileUrl);
              break;
            default:
              break;
          }
        }
      },
    });

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveImage = () => {
    if (group) {
      const list = getValues(group);
      const targetIndex = list.findIndex((e: any) => e.name === item.name);
      list.splice(targetIndex, 1);
      methods.setValue(group, list);
    }

    if (!group) {
      switch (item.name) {
        case "carThumbnail":
          methods.setValue("imageUrl", "");
          break;
        default:
          break;
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      fileInputRef.current?.click();
    }
  };

  return (
    <div className="rounded-lg bg-white relative">
      <div className="flex flex-col relative">
        <FormInputLabel item={item} />

        <div
          role="button"
          tabIndex={0}
          className="mb-2 text-center flex items-center justify-center bg-[#EAEAEA] overflow-hidden h-36 rounded-xl relative cursor-pointer"
          onClick={() => fileInputRef.current?.click()}
          onKeyDown={handleKeyDown}
        >
          {url ? (
            <img
              src={url}
              alt="Uploaded file"
              className="max-w-full h-auto rounded"
            />
          ) : (
            <img
              src={item.template}
              alt={item.name}
              className="max-w-full h-auto"
            />
          )}
          <div className="absolute">
            {isUploading && <Loader variant="secondary" />}
          </div>
        </div>

        {url && (
          <button
            type="button"
            className="absolute top-6 -right-2 flex items-center justify-center text-sm bg-[#ff4d4f] text-white border border-white rounded-full w-6 h-6"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveImage();
            }}
          >
            &#x2715;
          </button>
        )}
      </div>

      <input
        ref={fileInputRef}
        id={`${item.name}-input`}
        type="file"
        disabled={item.disabled}
        placeholder={item.placeholder}
        className="hidden"
        onChange={handleFileUpload}
        accept={item?.validation?.accept}
      />
    </div>
  );
};

export default FormInputImage;
