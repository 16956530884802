import client from "@/lib/axios-client";

export const fetchCarDocuments = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/documents`, { params });
};

export const fetchInspectionReport = (
  params: URLSearchParams,
  carId: string
) => {
  return client.get(`/v1/inventory/car/${carId}/inspection/marketplace`, {
    params,
  });
};

export const fetchValuationReport = (
  params: URLSearchParams,
  loanId: string
) => {
  return client.get(`/v2/origination/${loanId}/valuations`, {
    params,
  });
};

export const postTrackerInfo = (data: any) => {
  return client.post("/v1/inventory/trackers", data);
};

export const fetchTrackerInfo = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/trackers`, {
    params,
  });
};

export const updateTrackerInfo = (data: any, id: number) => {
  return client.put(`/v1/inventory/trackers/${id}`, data);
};

export const postCarDocument = (data: any) => {
  return client.post("/v1/inventory/car_document", data);
};

export const fetchCarDocument = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/car_document`, {
    params,
  });
};

export const editCarDocument = (data: any, carId: string) => {
  return client.put(`/v1/inventory/car_document/${carId}`, data);
};

export const fetchCarMakes = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/make`, {
    params,
  });
};

export const fetchCarModels = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/model`, {
    params,
  });
};

export const fetchFranchise = (params: URLSearchParams) => {
  return client.get(`/v1/franchise`, {
    params,
  });
};

export const fetchFranchiseById = (id: string) => {
  return client.get(`/v1/franchise/${id}`);
};

export const fetchOriginationLoanStatuses = (params: URLSearchParams) => {
  return client.get(`/v1/origination/loans/statuses`, {
    params,
  });
};

export const fetchOriginationLoans = (params: URLSearchParams) => {
  return client.get(`/v1/origination/dig/loans`, {
    params,
  });
};

export const fetchDealerOriginatedLoans = (
  id: string,
  params: URLSearchParams
) => {
  return client.get(`/v1/dealer/${id}/loans`, {
    params,
  });
};

export const fetchFailedCarModerations = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/cars/moderations`, {
    params,
  });
};

export const disputeCarModeration = (data: any, id: string) => {
  return client.put(`/v1/inventory/cars/moderations/${id}`, data);
};

export const fetchCreateCarConfig = (id: string) => {
  return client.get(`/v1/inventory/forms/${id}`);
};

export const fetchDataSource = (url: string, params: URLSearchParams) => {
  return client.get(`${url}`, {
    params,
  });
};

export const fetchStates = (params: URLSearchParams) => {
  return client.get(`/v1/state`, {
    params,
  });
};

export const fetchCities = (params: URLSearchParams) => {
  return client.get(`/v1/city`, {
    params,
  });
};

export const fetchColors = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/marketplace/colors`, {
    params,
  });
};

export const fetchBodyTypes = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/body_type`, {
    params,
  });
};

export const imageUpload = (data: any) => {
  return client.post(`/image/upload`, data);
};

export const documentUpload = (data: any) => {
  return client.post(`/document/upload`, data);
};

export const fetchCarFeatures = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/feature`, {
    params,
  });
};

export const createInventory = (data: any) => {
  return client.post(`/v1/inventory/car`, data);
};

export const addCarMedia = (data: any) => {
  return client.post(`/v1/inventory/car_media`, data);
};

export const addCarFeatures = (data: any) => {
  return client.post(`/v1/inventory/car_feature`, data);
};

export const updateCarDetails = (id: string, data: any) => {
  return client.put(`/v1/inventory/car/${id}`, data);
};

export const fetchCarInspection = (carId: string, params: URLSearchParams) => {
  return client.get(`/v1/inventory/car/${carId}/inspection/marketplace`, {
    params,
  });
};

export const fetchLoanUtility = (id: string) => {
  return client.get(`/v1/dealer/loan-utility/${id}`);
};
