import { Dependency } from "@/components/formElements/mui-form/types";
import {
  getCompressedUrl,
  handleApiError,
} from "@/components/formElements/mui-form/utils";
import client from "@/lib/axios-client";
import useCarFormStore from "@/states/create-car";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const params = {
  page_size: "1000",
};

const fetchMakes = async (params: URLSearchParams) => {
  const response = await client.get("/v1/inventory/make", { params });
  const data = JSON.parse(response.data);
  return data.makeList.map((make: any) => ({
    id: make.id,
    value: make.name,
    label: make.name,
  }));
};

const fetchModels = async (makeId: string) => {
  const response = await client.get(`/v1/inventory/model?make_id=${makeId}`, {
    params,
  });
  const data = JSON.parse(response.data);
  return data.modelList.map((model: any) => ({
    id: model.id,
    value: model.name,
    label: model.name,
  }));
};

const fetchColors = async () => {
  const response = await client.get("/v1/inventory/marketplace/colors", {
    params,
  });
  const data = JSON.parse(response.data);
  const uniqueColors = Array.from(new Set(data.colors));
  return uniqueColors.map((color: any) => ({
    value: color,
    label: color,
  })) as any;
};

const fetchStates = async (country: string) => {
  const response = await client.get(`/v1/state?country_code=${country}`, {
    params,
  });
  const data = JSON.parse(response.data);
  return data.states.map((state: any) => ({
    id: state.id,
    value: state.name,
    label: state.name,
  }));
};

const fetchCities = async (stateId: string) => {
  const response = await client.get(`/v1/city?state_id=${stateId}`, { params });
  const data = JSON.parse(response.data);
  return data.cities.map((city: any) => ({
    id: city.id,
    value: city.name,
    label: city.name,
  }));
};

const fetchFranchises = async () => {
  const response = await client.get("/v1/franchise", { params });
  const data = JSON.parse(response.data);
  return data.result.map((franchise: any) => ({
    id: franchise.id,
    value: franchise.name,
    label: franchise.name,
  }));
};

const fetchFranchise = async (id: string) => {
  const response = await client.get(`/v1/franchise/${id}`);
  const data = JSON.parse(response.data);
  return data;
};

const fetchFeatures = async (search: string) => {
  let url = "/v1/inventory/feature?page_size=500";
  if (search) {
    url = `${url}&search=${search}`;
  }
  const response = await client.get(url);
  const data = JSON.parse(response.data);
  return data.featureList.map((feature: any) => ({
    name: feature.id.toString(),
    label: feature.name,
    type: "checkbox",
  }));
};

const fetchBodyTypes = async () => {
  const response = await client.get("/v1/inventory/body_type", { params });
  const data = JSON.parse(response.data);
  return data.bodyTypeList.map((bodyType: any) => ({
    id: bodyType.id,
    value: bodyType.name,
    label: bodyType.name,
  }));
};

const fetchCreateCarForm = async () => {
  const response = await client.get("/v1/inventory/forms/dealer-create-car");
  const data = JSON.parse(response.data);
  const config = JSON.parse(data.form.config);
  config.form_id = data.form.id;
  return config;
};

const decodeVin = async (vin: string) => {
  const response = await client.get(`/v1/inventory/vin-decode/${vin}`);
  return JSON.parse(response.data);
};

const fetchCarTrims = async (params: URLSearchParams) => {
  const response = await client.get(`/v1/inventory/trims`, { params });
  const data = JSON.parse(response.data);
  return data.modelList.map((model: any) => ({
    id: model.id,
    value: model.name,
    label: model.name,
  }));
};

const decodeCity = async (id: string) => {
  const response = await client.get(`/v1/city/${id}`);
  const data = JSON.parse(response.data);
  return {
    cityId: data.id,
    cityName: data.name,
    stateId: data.state.id,
    stateName: data.state.name,
  };
};

export const useFetchers = ({
  country,
  makeId,
  stateId,
  search,
  franchiseId,
  vinLookup,
  vin,
  modelId,
}: Dependency) => {
  const query = useQueryClient();
  const { setAlert } = useCarFormStore();

  const { data: makes } = useQuery({
    queryKey: ["makes"],
    queryFn: () => {
      const params = new URLSearchParams({
        use_cache: "false",
        page_size: "1000",
      });
      return fetchMakes(params);
    },
    refetchOnWindowFocus: false,
  });

  const { data: models } = useQuery({
    queryKey: ["models", makeId],
    queryFn: () => fetchModels(makeId),
    enabled: !!makeId,
    refetchOnWindowFocus: false,
  });

  const { data: colors } = useQuery({
    queryKey: ["colors"],
    queryFn: fetchColors,
    refetchOnWindowFocus: false,
  });

  const { data: states } = useQuery({
    queryKey: ["states", country],
    queryFn: () => fetchStates(country),
    enabled: !!country,
    refetchOnWindowFocus: false,
  });

  const { data: cities } = useQuery({
    queryKey: ["cities", stateId],
    queryFn: () => fetchCities(stateId),
    enabled: !!stateId,
    refetchOnWindowFocus: false,
  });

  const { data: franchises } = useQuery({
    queryKey: ["franchises"],
    queryFn: fetchFranchises,
    refetchOnWindowFocus: false,
  });

  const { data: franchise } = useQuery({
    queryKey: ["franchise", franchiseId],
    queryFn: () => fetchFranchise(franchiseId),
    enabled: !!franchiseId,
    refetchOnWindowFocus: false,
  });

  const {
    data: features,
    isFetching: isFetchingFeatures,
    isLoading: isLoadingFeatures,
  } = useQuery({
    queryKey: ["features", search],
    queryFn: () => fetchFeatures(search),
    refetchOnWindowFocus: false,
  });

  const { data: bodyTypes } = useQuery({
    queryKey: ["bodyTypes"],
    queryFn: fetchBodyTypes,
    refetchOnWindowFocus: false,
  });

  const { data: vinData, isFetching: isFetchingVin } = useQuery({
    queryKey: ["vin", vinLookup],
    queryFn: () => decodeVin(vinLookup),
    enabled: vinLookup?.length === 17 && vin?.length === 17,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: () => {
      query.invalidateQueries(["makes"]);
    },
    onError: (error) => {
      setAlert(handleApiError(error));
    },
  });

  const { data: trims } = useQuery({
    queryKey: ["trims", makeId, modelId],
    queryFn: () => {
      const params = new URLSearchParams({
        make_id: makeId,
        model_id: modelId,
        page_size: "300",
      });
      return fetchCarTrims(params);
    },
    enabled: !!makeId && !!modelId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: cityData } = useQuery({
    queryKey: ["cityById", franchise?.cityId],
    queryFn: () => decodeCity(franchise?.cityId),
    enabled: !!franchise?.cityId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    makes,
    models,
    colors,
    states,
    cities,
    franchises,
    features,
    bodyTypes,
    franchise,
    isSearchingFeatures: isFetchingFeatures || isLoadingFeatures,
    isDecodingVin: isFetchingVin,
    vinData,
    trims,
    cityData,
  };
};

export const useCreateCarForm = () => {
  return useQuery({
    queryKey: ["create-car-form"],
    queryFn: fetchCreateCarForm,
    refetchOnWindowFocus: false,
  });
};

export const uploadFile = async (file?: File) => {
  if (!file) return "";

  const formData = new FormData();
  formData.append("file", file);

  const response = await client.post("/image/upload", formData);
  const data = JSON.parse(response.data);

  if (data.file.mimetype === "application/pdf") {
    return data.file.url;
  }

  const url = await getCompressedUrl(data.file.url);
  return url;
};

const useUploadFile = () => {
  return useMutation((file: File) => uploadFile(file), {
    onError: (error) => {
      console.error("Error uploading file:", error);
    },
  });
};

export const useUploadImage = useUploadFile;

export const useUploadDocument = useUploadFile;

export const useCreateCar = () => {
  return useMutation({
    mutationFn: (data: any) => client.post("/v1/inventory/car", data),
  });
};

export const useAddCarMedia = () => {
  return useMutation({
    mutationFn: (data: any) => client.post("/v1/inventory/car_media", data),
  });
};

export const useAddCarDocuments = () => {
  return useMutation({
    mutationFn: (data: any) => client.post("/v1/inventory/car_document", data),
  });
};

export const useAddCarFeatures = () => {
  return useMutation({
    mutationFn: (data: any) => client.post("/v1/inventory/car_feature", data),
  });
};

export const useUpdateCar = () => {
  return useMutation({
    mutationFn: ({ car_id, ...data }: any) => {
      return client.put(`/v1/inventory/car/${car_id}`, data);
    },
  });
};
