import React, { useState, useEffect } from "react";
import LoadingCarCard from "./LoadingCarCard";
import type { PaginationProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import InventoryCarGrid from "./InventoryCarGrid";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { useTranslation } from "react-i18next";
import { IVariant } from "@/types";

interface IAvailableCarList {
  carsResult: any;
  inventoryLoading: boolean;
  viewType: string;
  variant: IVariant;
  loanId: string;
  partnerId: string;
  tabValue: string;
  productData: any;
  simulate: string;
  query: any;
  carId: string;
  setCarId: React.Dispatch<React.SetStateAction<string>>;
}

export default function AvailableCarList({
  carsResult,
  inventoryLoading,
  viewType,
  variant,
  loanId,
  partnerId,
  tabValue,
  productData,
  simulate,
  query,
  carId,
  setCarId,
}: Readonly<IAvailableCarList>) {
  const navigate = useNavigate();
  const { t } = useTranslation("inventory");
  const location = useLocation();

  // states
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (query.page_number && typeof query.page_number == "string") {
      setCurrent(parseInt(query.page_number));
    }
  }, [query.page_number]);

  const handlePageChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrent(page);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        page_number: page,
        page_size: pageSize,
      })}${location.hash || ""}`
    );
  };

  return (
    <div>
      {carsResult?.carList?.length !== 0 ? (
        <React.Fragment>
          {inventoryLoading ? (
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
              {Array.from({ length: 12 }, (_, index) => (
                <div key={index}>
                  <LoadingCarCard />
                </div>
              ))}
            </div>
          ) : (
            <>
              <InventoryCarGrid
                variant={variant}
                carsResult={carsResult}
                loanId={loanId}
                partnerId={partnerId}
                tabValue={tabValue}
                productData={productData}
                simulate={simulate}
                viewType={viewType}
                carId={carId}
                setCarId={setCarId}
              />
              <div className="inventory-pagination">
                <Pagination
                  current={current}
                  onChange={handlePageChange}
                  defaultPageSize={12}
                  total={carsResult?.pagination?.total}
                  showSizeChanger={false}
                />
              </div>
            </>
          )}
        </React.Fragment>
      ) : (
        <div className="w-full max-w-full p-3 md:p-4">
          <div className="flex justify-center items-center min-h-[500px] xl:min-h-[700px] border border-solid rounded-lg">
            <div className="">
              <img
                src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                alt="Note"
                className="my-0 mx-auto w-full h-auto"
              />
              <div className="text-base md:text-lg text-[#6b7280] font-normal">
                {t("you-do-not-have-a-car")}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
