import { InputFieldStyle } from "@/layouts/application-layouts/styles";
import { TextField } from "@mui/material";
import { FormInputProps } from "./types";
import { Loader } from "@/pages/auth/styles/loader";
import FormInputLabel from "./FormInputLabel";
import FormInputComment from "./FormInputComment";

export const FormInputText: React.FC<FormInputProps> = ({
  item,
  field,
  methods,
  data,
  disabled,
}: FormInputProps) => {
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const error = errors[item.name]?.message;

  return (
    <div className="space-y-2 relative">
      <FormInputLabel item={item} />
      <TextField
        {...field}
        fullWidth
        error={!!error}
        type={item.type}
        disabled={disabled || item.disabled}
        hidden={item.hidden}
        placeholder={item.placeholder}
        onChange={field.onChange}
        inputProps={{
          maxLength: item.validation?.maxLength,
          pattern: item.validation?.pattern,
        }}
        sx={InputFieldStyle}
      />
      {data?.isDecodingVin && item.name === "vin" && (
        <div className="absolute top-[34px] right-[10px]">
          <Loader variant="secondary" />
        </div>
      )}

      <FormInputComment item={item} />
      {error && <span className="text-red-600 text-sm">{error}</span>}
    </div>
  );
};
