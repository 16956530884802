import {
  InputFieldStyle,
  InputLabelStyle,
} from "@/layouts/application-layouts/styles";
import { Loader } from "@/pages/auth/styles/loader";
import { FormLabel, TextField } from "@mui/material";
import { FormInputProps } from "./types";

export const FormInputSearch: React.FC<FormInputProps> = ({
  item,
  field,
  methods,
  data,
}: FormInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    methods.setValue("searchFeatures", e.target.value);
  };

  return (
    <div className="space-y-2 col-span-2">
      <FormLabel sx={InputLabelStyle}>{item.label}</FormLabel>
      <TextField
        {...field}
        fullWidth
        error={!!field.error}
        type={item.type}
        disabled={item.disabled}
        hidden={item.hidden}
        placeholder={item.placeholder}
        onChange={handleChange}
        sx={InputFieldStyle}
      />
      <div className="flex justify-center">
        {data?.isSearchingFeatures && <Loader variant="secondary" />}
      </div>
    </div>
  );
};
