import { Form, Input, message } from "antd";
import type { FormProps } from "antd";
import { SettingFormContainer } from "@/layouts/application-layouts/styles";
import storage from "@l/storage";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { getCountryFlag } from "@/utils/getCountryFlag";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import { getCountryTelCodeLength } from "@/utils/getCountryTelCodeLength";
import { Loader } from "@/pages/auth/styles/loader";

type FieldType = {
  dealerName: string;
  email: string;
  phonenumber: string;
  whatsapp_number: string;
  display_phone_number: string;
  extra_display_phone_number: string;
  dealershipName: string;
};

type TFranchiseDataType = {
  name: string;
  email: string;
  phonenumber: string;
  extraDisplayPhoneNumber: string;
  instagramHandle: string;
  facebookHandle: number;
  address: string;
  whatsappNumber: string;
  displayPhoneNumber: string;
};

export default function ProfileForm({
  franchiseData,
  refetch,
}: Readonly<{
  franchiseData: TFranchiseDataType;
  refetch: () => void;
}>) {
  const [form] = Form.useForm();
  const user = storage.getUser();
  const country = storage.getCountry();

  const updateFranchiseData = useMutation({
    mutationFn: (payload) =>
      client.put(`/v1/franchise/${user.company.id}`, payload),
  });

  useEffect(() => {
    form.setFieldsValue({
      name: franchiseData?.name,
      email: franchiseData?.email,
      phonenumber: franchiseData?.phonenumber?.slice(3),
      whatsapp_number: franchiseData?.whatsappNumber?.slice(3),
      display_phone_number: franchiseData?.displayPhoneNumber?.slice(3),
      extra_display_phone_number:
        franchiseData?.extraDisplayPhoneNumber?.slice(3),
      facebook_handle: franchiseData?.facebookHandle,
      instagram_handle: franchiseData?.instagramHandle,
      dealershipName: user?.company?.name,
    });
  }, [form, franchiseData, user]);

  const handleProfileForm: FormProps<FieldType>["onFinish"] = (values) => {
    delete (values as any).dealershipName;

    updateFranchiseData
      .mutateAsync({
        ...values,
        phonenumber: values.phonenumber
          ? `${getCountryTelCode(country)}${values.phonenumber}`
          : "",
        whatsapp_number: values.whatsapp_number
          ? `${getCountryTelCode(country)}${values.whatsapp_number}`
          : "",
        display_phone_number: values.display_phone_number
          ? `${getCountryTelCode(country)}${values.display_phone_number}`
          : "",
        extra_display_phone_number: values.extra_display_phone_number
          ? `${getCountryTelCode(country)}${values.extra_display_phone_number}`
          : "",
        onboarding_source: user.id,
      } as any)
      .then(() => {
        message.success("Profile updated successfully");
        refetch();
      });
  };

  return (
    <SettingFormContainer className="w-full max-w-full">
      <Form
        name="myProfile"
        onFinish={handleProfileForm}
        requiredMark={false}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item<FieldType>
          label="Dealer name"
          name="dealershipName"
          rules={[{ required: false, message: "Please enter dealer name" }]}
        >
          <Input readOnly className="cursor-not-allowed custom-ant-input" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Dealership name"
          name="name"
          rules={[{ required: true, message: "Please enter dealership name" }]}
        >
          <Input readOnly className="cursor-not-allowed custom-ant-input" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "The input is not valid email",
            },
            {
              required: true,
              message: "Please enter email",
            },
          ]}
        >
          <Input className="custom-ant-input" />
        </Form.Item>

        <div className="flex flex-row gap-4 w-full">
          <Form.Item
            name="phonenumber"
            label="Phone number"
            rules={[
              { required: true, message: "Please enter phone number" },
              {
                min: getCountryTelCodeLength(country),
                max: getCountryTelCodeLength(country),
                message: `Please enter a valid phone number`,
              },
            ]}
            className="w-full"
          >
            <Input
              addonBefore={
                <img
                  src={getCountryFlag(country)}
                  alt="flag"
                  className="w-8 h-8"
                />
              }
              prefix={getCountryTelCode(country)}
              maxLength={getCountryTelCodeLength(country)}
              onInput={(event: any) => {
                const value = event.target.value;
                event.target.value = value.replace(/\D/g, "");
              }}
              onPaste={(event) => {
                const pastedData = event.clipboardData.getData("Text");
                if (!/^\d+$/.test(pastedData)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="whatsapp_number"
            label="Whatsapp Phone number"
            rules={[
              {
                required: false,
                message: "Please enter whatsapp phone number",
              },
              {
                min: getCountryTelCodeLength(country),
                max: getCountryTelCodeLength(country),
                message: `Please enter a valid phone number`,
              },
            ]}
            className="w-full"
          >
            <Input
              addonBefore={
                <img
                  src={getCountryFlag(country)}
                  alt="flag"
                  className="w-8 h-8"
                />
              }
              prefix={getCountryTelCode(country)}
              maxLength={getCountryTelCodeLength(country)}
              onInput={(event: any) => {
                const value = event.target.value;
                event.target.value = value.replace(/\D/g, "");
              }}
              onPaste={(event) => {
                const pastedData = event.clipboardData.getData("Text");
                if (!/^\d+$/.test(pastedData)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>

        <Form.Item
          name="display_phone_number"
          label="Display phone number"
          tooltip="This is the number we will display to customers on the Autochek website"
          rules={[
            { required: false, message: "Please enter display phone number" },
            {
              min: getCountryTelCodeLength(country),
              max: getCountryTelCodeLength(country),
              message: `Please enter a valid phone number`,
            },
          ]}
        >
          <Input
            addonBefore={
              <img
                src={getCountryFlag(country)}
                alt="flag"
                className="w-8 h-8"
              />
            }
            prefix={getCountryTelCode(country)}
            maxLength={getCountryTelCodeLength(country)}
            onInput={(event: any) => {
              const value = event.target.value;
              event.target.value = value.replace(/\D/g, "");
            }}
            onPaste={(event) => {
              const pastedData = event.clipboardData.getData("Text");
              if (!/^\d+$/.test(pastedData)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="extra_display_phone_number"
          label="Extra Display phone number"
          rules={[
            {
              required: false,
              message: "Please enter extra display phone number",
            },
            {
              min: getCountryTelCodeLength(country),
              max: getCountryTelCodeLength(country),
              message: `Please enter a valid phone number`,
            },
            {
              pattern: new RegExp(/^\d+$/),
              message: "Please enter only numbers",
            },
          ]}
        >
          <Input
            addonBefore={
              <img
                src={getCountryFlag(country)}
                alt="flag"
                className="w-8 h-8"
              />
            }
            prefix={getCountryTelCode(country)}
            maxLength={getCountryTelCodeLength(country)}
            onInput={(event: any) => {
              const value = event.target.value;
              event.target.value = value.replace(/\D/g, "");
            }}
            onPaste={(event) => {
              const pastedData = event.clipboardData.getData("Text");
              if (!/^\d+$/.test(pastedData)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="instagram_handle"
          label="Instagram profile"
          rules={[
            { required: false, message: "Please enter instagram profile url" },
            {
              pattern: new RegExp(
                /^\s*(https:\/\/(www\.)?instagram\.com\/[a-z0-9._-]+(\/[a-zA-Z0-9._-]*)?\s*)$/i
              ),
              message: "Please enter a valid instagram profile URL",
            },
          ]}
        >
          <Input
            placeholder="https://www.instagram.com/example"
            className="custom-ant-input"
          />
        </Form.Item>

        <Form.Item
          name="facebook_handle"
          label="Facebook profile"
          rules={[
            { required: false, message: "Please enter facebook profile url" },
            {
              pattern: new RegExp(
                /^\s*(https:\/\/(www\.)?facebook\.com\/[a-z0-9._-]+(\/[a-zA-Z0-9._-]*)?\s*)$/i
              ),
              message: "Please enter a valid facebook profile URL",
            },
          ]}
        >
          <Input
            placeholder="https://www.facebook.com/example"
            className="custom-ant-input"
          />
        </Form.Item>

        <Form.Item>
          <button
            type="submit"
            className="text-sm text-[#30345E] font-semibold w-full max-w-[50%] xl:max-w-[35%] bg-[#FFB619] h-[48px] !rounded-full mt-5 xl:mt-6"
          >
            {updateFranchiseData.isLoading ? (
              <Loader variant="secondary" />
            ) : (
              "Update Profile"
            )}
          </button>
        </Form.Item>
      </Form>
    </SettingFormContainer>
  );
}
