import { InputLabelStyle, TextAreaFieldStyle } from "@/layouts/application-layouts/styles";
import { FormLabel, TextField } from "@mui/material";
import { FormInputProps } from "./types";

export const FormInputTextArea: React.FC<FormInputProps> = ({ item, field }: FormInputProps) => {
  return (
    <div className='space-y-2 col-span-2'>
      <FormLabel sx={InputLabelStyle}>{item.label}</FormLabel>
      <TextField
        {...field}
        fullWidth
        error={!!field.error}
        type={item.type}
        multiline
        rows={7}
        disabled={item.disabled}
        hidden={item.hidden}
        placeholder={item.placeholder}
        onChange={field.onChange}
        sx={TextAreaFieldStyle}
      />
    </div>
  );
};
