import { CreateCarFormValues } from "@/validations/create-car.schema";
import { Option } from "@crudmates/form-config";
import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { FormInputProps } from "./types";
import FormInputLabel from "./FormInputLabel";
import FormInputComment from "./FormInputComment";
import { FormDropdownStyle } from "@/layouts/application-layouts/styles";

export const FormInputDropdown: React.FC<FormInputProps> = ({
  field,
  item,
  options,
  methods,
  disabled,
}) => {
  const name = item.name as keyof CreateCarFormValues;
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const error = errors[name]?.message;

  const handleSelect = (_: any, event: any) => {
    const selectedOption = options?.find(
      (option) => option.label === event.target.innerText
    );
    const value = selectedOption?.value ?? "";
    const id = selectedOption?.id ?? "";

    switch (name) {
      case "make":
        methods.setValue("make", value);
        methods.setValue("makeId", id);
        break;
      case "model":
        methods.setValue("model", value);
        methods.setValue("modelId", id);
        break;
      case "state":
        methods.setValue("state", value);
        methods.setValue("stateId", id);
        break;
      case "city":
        methods.setValue("city", value);
        methods.setValue("cityId", id);
        break;
      case "franchise":
        methods.setValue("franchise", value);
        methods.setValue("franchiseId", id);
        break;
      case "bodyType":
        methods.setValue("bodyType", value);
        methods.setValue("bodyTypeId", id);
        break;
      case "trim":
        methods.setValue("trim", value);
        methods.setValue("trimId", id);
        break;
      default:
        methods.setValue(name, value);
        break;
    }
  };

  return (
    <div className="space-y-2">
      <FormInputLabel item={item} />
      <Autocomplete
        disablePortal
        options={options ?? []}
        getOptionLabel={(option: Option) => option.label || ""}
        onChange={(event) => handleSelect(field, event)}
        isOptionEqualToValue={(option: Option, value: Option) =>
          option.value === value.value
        }
        fullWidth
        disabled={disabled || item.disabled}
        value={options?.find((option) => option.value === field.value) || null}
        sx={FormDropdownStyle}
        renderInput={(params) => (
          <TextField
            {...params}
            value={field.value}
            placeholder={item.placeholder}
            variant="outlined"
            fullWidth
            error={!!error}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "16px",
                color: "#30345E",
                fontFamily: "OutfitRegular",
                height: "15px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px",
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index });
            return <Chip key={key} label={option.label} {...rest} />;
          })
        }
      />
      <FormInputComment item={item} />
      {error && <span className="text-red-600 text-sm">{error}</span>}
    </div>
  );
};
