import { Item } from "@crudmates/form-config";
import { Fragment } from "react";

export default function FormInputComment({ item }: { item: Item }) {
  return (
    <Fragment>
      {item?.comment && (
        <p className="text-xs text-gray-400 leading-tight">{item?.comment}</p>
      )}
    </Fragment>
  );
}
