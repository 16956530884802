import EditInventoryHeader from "@/components/blocks/inventory/EditInventoryHeader";
import Button from "@/components/button";
import ImageUploadInput from "@/components/formElements/image-upload";
import client from "@/lib/axios-client";
import { message } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { device } from "@/utils/device";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const GridForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 4rem;
  width: 65%;
  margin-inline: auto;
  margin-block: 3rem;
  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

function AddCarImagesForm({ media, id }: any) {
  const [images, setImages] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("inventory");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const addImages = (data: any) => {
    const previousData = images;
    previousData.push(data);
    setImages(previousData);
  };

  function convertCamelCaseToSentence(str: string) {
    const result = str.replace(/([A-Z])/g, " $1").toLowerCase();
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const onSubmitData = async () => {
    setLoading(true);
    const payload = {
      car_id: id,
      payload: images,
    };
    try {
      const response: any = await client.post(
        `/v1/inventory/car_media`,
        payload
      );
      if (response) {
        message.success("Car updated successfully");
        setLoading(false);
        queryClient.invalidateQueries(["car_media_by_id"]);
        navigate(`/inventory/${id}`, { replace: true });
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || "Error updating car"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <GridForm>
        {media?.carMediaList.map((x: any, index: number) => {
          return (
            <ImageUploadInput
              key={index}
              name={x.name}
              image={x}
              onUploaded={addImages}
              label={convertCamelCaseToSentence(x.name)}
            />
          );
        })}
      </GridForm>
      <div
        style={{
          display: "grid",
          placeContent: "center",
          margin: "24px 0 48px",
        }}
      >
        <Button
          variant="secondary"
          onClick={onSubmitData}
          width={"w-60"}
          isLoading={loading}
          style={{ borderRadius: "60px" }}
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
}

export default AddCarImagesForm;
