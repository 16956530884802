import useCarFormStore from "@/states/create-car";
import { Item, Section } from "@crudmates/form-config";
import { Alert, Snackbar } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  step: number;
  backable?: boolean;
  skipable?: boolean;
  section: Section<Item>;
  children: ReactNode;
};

type BackButtonProps = {
  step: number;
};

const SectionWrapper: React.FC<Props> = ({
  children,
  step,
  section,
  backable,
  skipable,
}) => {
  const { alert, setAlert } = useCarFormStore();

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>
      <div
        className={`w-full max-w-full lg:max-w-full ${
          step === 4 ? "xl:max-w-full" : "xl:max-w-[55%]"
        } my-0 mx-auto flex flex-col gap-4 md:gap-8 mb-12`}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-lg md:text-2xl text-[#30345E] font-semibold">
            Add a car
          </h1>
          <p className="text-lg md:text-2xl text-[#30345E] font-semibold">
            {step}/5
          </p>
        </div>
        <div className="bg-white rounded-2xl">
          <div key={section.name}>
            <div className="flex justify-between items-center border-b border-solid border-[#E5E7EB] p-4 xl:px-5 xl:py-4">
              <div className="flex gap-2">
                {backable && <BackButton step={step} />}
                <h1 className="text-base xl:text-xl font-semibold text-[#30345E]">
                  {section.label}
                </h1>
              </div>
              {skipable && <SkipStepButton step={step} />}
            </div>
            <div className="p-4 xl:px-5 xl:pt-4 xl:pb-14">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper;

const BackButton = ({ step }: BackButtonProps) => {
  const { setStep } = useCarFormStore();

  return (
    <button onClick={() => setStep(step - 1)}>
      <img
        src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
        alt="left-arrow"
      />
    </button>
  );
};

const SkipStepButton = ({ step }: BackButtonProps) => {
  const { setStep } = useCarFormStore();

  return (
    <button
      className="hover:underline underline text-[#6E74B5] font-medium"
      onClick={() => setStep(step + 1)}
    >
      Skip this step
    </button>
  );
};
