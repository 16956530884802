import {
  useAddCarDocuments,
  useAddCarFeatures,
  useAddCarMedia,
  useCreateCar,
  useUpdateCar,
} from "@/hooks/use-fetchers";
import {
  persistCarForm,
  resetCarForm,
} from "@/pages/applications/car-form/helper";
import { Loader } from "@/pages/auth/styles/loader";
import useCarFormStore from "@/states/create-car";
import { CreateCarFormValues } from "@/validations/create-car.schema";
import { Config, Item } from "@crudmates/form-config";
import { Button } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import {
  getCreateCarDocsPayload,
  getCreateCarFeaturesPayload,
  getCreateCarImagesPayload,
  getCreateCarPayload,
  getUpdateCarPayload,
  validateFormSection,
} from "./utils";

type Props = {
  step: number;
  item: Item;
  config?: Config;
  methods: UseFormReturn<CreateCarFormValues, any>;
};

const FormButton: React.FC<Props> = ({ step, item, methods, config }) => {
  const formValues = methods.getValues();
  const { mutate: createCar, isLoading: isCreatingCar } = useCreateCar();
  const { mutate: addCarMedia, isLoading: isAddingCarMedia } = useAddCarMedia();
  const { mutate: addCarDocuments, isLoading: isAddingCarDocuments } =
    useAddCarDocuments();
  const { mutate: addCarFeatures, isLoading: isAddingCarFeatures } =
    useAddCarFeatures();
  const { mutate: updateCar, isLoading: isUpdatingCar } = useUpdateCar();

  const { currentStep, completedSteps, setStep, setCompleted, setAlert } =
    useCarFormStore();
  const isValid = validateFormSection(currentStep, formValues);

  const isLoading =
    isCreatingCar ||
    isAddingCarMedia ||
    isAddingCarDocuments ||
    isAddingCarFeatures ||
    isUpdatingCar;

  const disabled = !isValid || isLoading || completedSteps.includes(step);

  const handleClick = () => {
    switch (currentStep) {
      case 1:
        createCar(getCreateCarPayload(formValues), {
          onSuccess: (response) => {
            const data = JSON.parse(response.data);
            methods.setValue("carId", data.id);
            setCompleted(1);
            setStep(2);
          },
          onError(error: any) {
            const err = JSON.parse(error.response.data).error;
            setAlert(err);
          },
        });
        break;
      case 2:
        addCarMedia(getCreateCarImagesPayload(formValues), {
          onSuccess: () => {
            setCompleted(2);
            setStep(3);
          },
          onError(error: any) {
            const err = JSON.parse(error.response.data).error;
            setAlert(err);
          },
        });
        break;
      case 3:
        addCarDocuments(getCreateCarDocsPayload(formValues), {
          onSuccess: () => {
            setCompleted(3);
            setStep(4);
          },
          onError(error: any) {
            const err = JSON.parse(error.response.data).error;
            setAlert(err);
          },
        });
        break;
      case 4:
        addCarFeatures(getCreateCarFeaturesPayload(formValues), {
          onSuccess: () => {
            setStep(5);
          },
          onError(error: any) {
            const err = JSON.parse(error.response.data).error;
            setAlert(err);
          },
        });
        break;
      case 5:
        updateCar(getUpdateCarPayload(formValues, config), {
          onSuccess: () => {
            setCompleted(5);
            resetCarForm();
            window.location.replace(`/inventory/${formValues.carId}`);
            return;
          },
          onError(error: any) {
            const err = JSON.parse(error.response.data).error;
            setAlert(err);
          },
        });
        break;
      default:
        break;
    }

    persistCarForm(formValues);
  };

  return (
    <div className="col-span-2 flex justify-center my-5">
      <Button
        className="w-full max-w-[45%] my-0 mx-auto disabled:cursor-not-allowed"
        type="submit"
        variant="contained"
        disabled={disabled}
        sx={{
          bgcolor: "#ffb619",
          color: "#30345e",
          borderRadius: "40px",
          fontWeight: "600",
          fontFamily: "OutfitRegular",
          boxShadow: "none",
          height: "48px",
          "&:hover": {
            bgcolor: "#ffb619",
            color: "#30345e",
            boxShadow: "none",
          },
          "&:disabled": {
            cursor: "not-allowed",
          },
        }}
        onClick={handleClick}
      >
        {isLoading ? (
          <Loader variant="secondary" />
        ) : (
          <>{step === 5 ? "Save" : item.label}</>
        )}
      </Button>
    </div>
  );
};

export default FormButton;
