import { CreateCarFormValues } from "@/validations/create-car.schema";
import {
  Config,
  evaluateCondition,
  Item,
  Section,
} from "@crudmates/form-config";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import FormButton from "./FormButton";
import FormInputCheckbox from "./FormInputCheckbox";
import FormInputDocument from "./FormInputDocument";
import { FormInputDropdown } from "./FormInputDropdown";
import FormInputImage from "./FormInputImage";
import { FormInputNumber } from "./FormInputNumber";
import { FormInputRadio } from "./FormInputRadio";
import { FormInputSearch } from "./FormInputSearch";
import { FormInputText } from "./FormInputText";
import { FormInputTextArea } from "./FormInputTextArea";
import { Data, Dependency } from "./types";
import { getOptions } from "./utils";
import FormSnapshots from "@/components/refix/FormSnapshots";

type Props = {
  step: number;
  isCompleted: boolean;
  config?: Config;
  section: Section<Item>;
  data: Data;
  methods: UseFormReturn<CreateCarFormValues, any>;
  setAlert?: (alert: string) => void;
  dependencies: Dependency;
};

const InputConfig: React.FC<Props> = ({
  step,
  section,
  config,
  methods,
  data,
  setAlert,
  dependencies,
  isCompleted,
}) => {
  return (
    <fieldset
      className="flex flex-col md:grid md:grid-cols-2 gap-5"
      disabled={isCompleted}
    >
      {section.items.map((item, itemIndex) => {
        if (
          (item.conditions?.show || item?.hidden) &&
          !evaluateCondition(item.conditions?.show, dependencies)
        ) {
          return null;
        }

        const name = !["file", "button", "submit"].includes(item.type)
          ? item.name
          : "";

        return (
          <Controller
            key={itemIndex}
            name={name as keyof CreateCarFormValues}
            control={methods.control}
            rules={item.validation}
            render={({ field, fieldState: { error } }) => {
              switch (item.type) {
                case "text":
                case "password":
                case "email":
                case "tel":
                case "url":
                case "date": {
                  const disabled = !evaluateCondition(
                    item?.conditions?.enable,
                    methods.getValues?.()
                  );

                  return (
                    <FormInputText
                      key={field.name}
                      methods={methods}
                      field={field}
                      item={item}
                      data={data}
                      disabled={disabled}
                    />
                  );
                }
                case "number":
                  return (
                    <FormInputNumber
                      key={field.name}
                      methods={methods}
                      field={field}
                      item={item}
                      data={data}
                    />
                  );
                case "select": {
                  const options = getOptions(item, data);
                  const disabled = !evaluateCondition(
                    item?.conditions?.enable,
                    methods.getValues?.()
                  );

                  return (
                    <FormInputDropdown
                      key={field.name}
                      item={item}
                      methods={methods}
                      field={field}
                      options={options}
                      data={data}
                      disabled={disabled}
                    />
                  );
                }
                case "radio": {
                  const disabled = !evaluateCondition(
                    item?.conditions?.enable,
                    methods.getValues?.()
                  );
                  return (
                    <FormInputRadio
                      key={field.name}
                      field={field}
                      item={item}
                      methods={methods}
                      options={item.options}
                      data={data}
                      disabled={disabled}
                    />
                  );
                }

                case "file":
                  switch (item.subType) {
                    case "image":
                      return (
                        <FormInputImage
                          field={field}
                          item={item}
                          methods={methods}
                          setAlert={setAlert}
                        />
                      );
                    default:
                      return <></>;
                  }
                case "search":
                  return (
                    <FormInputSearch
                      field={field}
                      item={item}
                      methods={methods}
                      data={data}
                    />
                  );

                case "textarea":
                  return (
                    <FormInputTextArea
                      key={field.name}
                      field={field}
                      item={item}
                      methods={methods}
                    />
                  );

                case "group":
                  switch (item.subType) {
                    case "car-image-list": {
                      return (
                        <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
                          {item.subItems?.map((subItem: Item) => (
                            <FormInputImage
                              key={subItem.name}
                              group={item.name}
                              field={field}
                              item={subItem}
                              methods={methods}
                              setAlert={setAlert}
                            />
                          ))}
                          <FormSnapshots methods={methods} />
                        </div>
                      );
                    }
                    case "car-document-list": {
                      return (
                        <div className="col-span-2 grid grid-cols-2 gap-5">
                          {item.subItems?.map((subItem: Item) => (
                            <FormInputDocument
                              key={subItem.name}
                              group={item.name}
                              field={field}
                              item={subItem}
                              methods={methods}
                              setAlert={setAlert}
                            />
                          ))}
                        </div>
                      );
                    }
                    case "feature-list": {
                      return (
                        <div className="col-span-2 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                          {item.subItems?.map((subItem: Item) => (
                            <FormInputCheckbox
                              key={subItem.name}
                              group={item.name}
                              field={field}
                              item={subItem}
                              methods={methods}
                            />
                          ))}
                        </div>
                      );
                    }
                    default:
                      return <></>;
                  }
                case "button":
                case "submit":
                  return (
                    <FormButton
                      step={step}
                      item={item}
                      methods={methods}
                      config={config}
                    />
                  );
                default:
                  return <></>;
              }
            }}
          />
        );
      })}
    </fieldset>
  );
};
export default InputConfig;
