import React, { useState, useEffect } from "react";
import storage from "@l/storage";
import {
  ApplicationLayout,
  FilterContainer,
  Header,
  MobileHeader,
} from "@/layouts/application-layouts/styles";
import { Input, DatePicker, Table, TableProps } from "antd";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import InventoryCustomFilter from "@/layouts/application-layouts/InventoryFilter";
import { isMobile } from "react-device-detect";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { carSearchById, getCarList } from "@/hooks/use-loan";
import french from "antd/es/date-picker/locale/fr_FR";
import arabic from "antd/es/date-picker/locale/ar_EG";
import english from "antd/es/date-picker/locale/en_US";

type props = {
  variant: "inventory" | "change-car" | "select-car" | "repo" | "loan-transfer";
};

const CardSection = tw.div``;

const MyLeads = ({ variant }: props) => {
  const role = storage.getRole();
  const user = storage.getUser();
  const country = storage.getCountry();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const { RangePicker } = DatePicker;
  const [carId, setCarId]: any = useState("");
  const isAccountManager = storage.utilities.isAccountManager();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanId = queryParameters.get("loan_id");
  const [franchiseData, setFranchiseData]: any = useState([]);
  const { t } = useTranslation("inventory");
  const locale = storage.getLocale();
  const carVin = queryParameters.get("car_id_vin") as string;
  const from = queryParameters.get("from");
  const to = queryParameters.get("to");
  const carMakeId = queryParameters.get("make_id");
  const carModelId = queryParameters.get("model_id");
  const franchiseId = queryParameters.get("franchise_id");
  const franchise = queryParameters.get("franchise");
  const franchiseList = queryParameters.get("franchise_list");
  const pageNumber = queryParameters.get("page_number") ?? 1;
  const pageSize = queryParameters.get("page_size");
  const searchQuery = queryParameters.get("query") as string;
  const fromDate = queryParameters.get("from_date") as string;
  const to_date = queryParameters.get("to_date") as string;
  const status = queryParameters.get("status") as string;
  const [categoryId, setCategoryId] = useState("");

  let currentLocation: string;

  switch (variant) {
    case "select-car":
    case "loan-transfer":
      currentLocation = "applications/car-found";
      break;
    case "change-car":
      currentLocation = "applications/car-list";
      break;
    case "repo":
      currentLocation = "dealer/all-deals";
      break;
    default:
      currentLocation = "inventory";
  }

  const getFranchiseParams = () => {
    if (franchiseId) return { franchise_id: franchiseId };
    if (franchise) return { franchise_id: franchise };
    if (franchiseList) return { franchise_id: franchiseList };
    return {};
  };

  const carSearch = async () => {
    const franchiseParams = getFranchiseParams();

    const carSearchParams = {
      country: country,
      ...(carId && {
        query: carId,
        ...franchiseParams,
      }),
      ...(!carId && {
        ...(from && { year_low: from }),
        ...(to && { year_high: to }),
        ...(carMakeId && { make_id: carMakeId }),
        ...(carModelId && { model_id: carModelId }),
        ...franchiseParams,
        ...(pageNumber && { page_number: pageNumber || "" }),
        ...(pageSize && { page_size: pageSize }),
        ...(carVin && { query: carVin }),
        ...(searchQuery && { query: searchQuery }),
        ...(fromDate && { from_date: fromDate }),
        ...(to_date && { to_date: to_date }),
        ...(status && { status: status }),
      }),
    };

    const data = await carSearchById(carSearchParams);
    return data;
  };

  useQuery({
    queryKey: ["fetch_category_id"],
    queryFn: () => {
      const params = new URLSearchParams({
        search: "repo",
      });
      return client.get(`/v1/inventory/category`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filterCategoryId = parsedResults?.categoryList?.find(
        (item: any) => item?.name === "Repossessed Sale"
      )?.id;
      setCategoryId(filterCategoryId);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    document.title = `Dealer Plus | Inventory [${role}]`;
  }, [role]);

  const title = "My Leads";

  const { isLoading: loadingFranchise } = useQuery({
    queryKey: ["get_franchise", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        page_size: "1000",
      });
      return client.get(`/v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedFranchise = JSON.parse(res?.data);
      const franchises = parsedFranchise?.result?.map((franchise: any) => {
        return {
          label: franchise?.name,
          value: franchise?.id,
        };
      });
      setFranchiseData(franchises);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        query: value,
      })}`
    );
  };

  const handleDateFilter = (dateString: any) => {
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        from_date: dateString
          ? moment(dateString[0].$d).format("YYYY-MM-DD")
          : "",
        to_date: dateString
          ? moment(dateString[1].$d).format("YYYY-MM-DD")
          : "",
      })}`
    );
  };

  const handleFranchiseSelect = (e: any, newValue: any) => {
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        franchise_id: newValue?.value,
      })}`
    );
  };

  const getLocalizedText = (locale: any) => {
    if (locale === "fr") {
      return french;
    } else if (locale === "ar") {
      return arabic;
    } else {
      return english;
    }
  };
  const localizedText = getLocalizedText(locale);

  interface DataType {
    key: string;
    name: string;
    phone_number: string;
    interest: string;
    location: string;
    date: string;
  }

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Interested in",
      dataIndex: "interest",
      key: "interest",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Brown",
      phone_number: "08068995689",
      location: "Ikeja, Lagos",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "2",
      name: "John Brown",
      phone_number: "08068995689",
      location: "Ikeja, Lagos",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "3",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "4",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "5",
      name: "John Brown",
      phone_number: "08068995689",
      location: "Ikeja, Lagos",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "6",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "7",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "8",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
    {
      key: "9",
      name: "John Brown",
      phone_number: "08068995689",
      location: "New York No. 1 Lake Park",
      interest: "Toyota Corolla 2016",
      date: new Date().toLocaleDateString(),
    },
  ];

  return (
    <React.Fragment>
      <Header style={{ marginBottom: 16, border: 0 }}>
        {isMobile ? (
          <MobileHeader>
            <div className="header">{title}</div>
          </MobileHeader>
        ) : (
          <div className="title">{title}</div>
        )}
      </Header>
      <ApplicationLayout style={{ paddingTop: "1rem" }}>
        <FilterContainer>
          <div className="filter-field">
            <div className="input-field">
              <div className="label mobile-input-field">
                {t("Search Leads")}
              </div>
              <Input
                placeholder={t("search-inventory-placeholder")}
                onChange={(val) => handleFilterSearch(val)}
                prefix={
                  <img
                    src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                    alt="search-icon"
                  />
                }
                allowClear
              />
            </div>

            <div className="input-field mobile-input-field">
              <div className="label">{t("Filter By Date")}</div>
              <RangePicker
                locale={localizedText}
                onChange={handleDateFilter}
                allowClear
              />
            </div>
          </div>
          <div className="filter-field">
            {isAccountManager && (
              <div className="input-field mobile-input-field">
                <div className="label">{t("filter-by-dealers")}</div>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    style={{ width: 250 }}
                    id="franchise_select"
                    disablePortal
                    loading={loadingFranchise}
                    options={franchiseData}
                    onChange={handleFranchiseSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("select-franchise")}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
            <div className="input-field mobile-input-field">
              {/* <button className="text-[#30345E] text-sm border border-solid border-[#30345E] font-semibold rounded-full flex items-center px-10 py-3">
                More filters
              </button> */}
              {/* <InventoryCustomFilter query={query} loading={false} /> */}
            </div>
          </div>
        </FilterContainer>
        <Table columns={columns} dataSource={data} />;
      </ApplicationLayout>
    </React.Fragment>
  );
};

export default MyLeads;
