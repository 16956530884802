import { InputLabelStyle } from "@/layouts/application-layouts/styles";
import { FormLabel } from "@mui/material";
import { Item } from "@crudmates/form-config";

export default function FormInputLabel({ item }: { item: Item }) {
  return (
    <FormLabel sx={InputLabelStyle}>
      {item.label}{" "}
      {item.validation?.required && (
        <span className="text-sm text-red-600">*</span>
      )}
    </FormLabel>
  );
}
