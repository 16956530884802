import React, { useState, useRef, useEffect } from "react";
import InventoryCustomFilter from "@/layouts/application-layouts/InventoryFilter";
import { DateField, SearchField } from "@/layouts/application-layouts/styles";
import { Input, DatePicker } from "antd";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { getLocalizedText } from "@/utils/locale-datepicker-text";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { IVariant } from "@/types";

interface ITableFieldsFilter {
  variant: IVariant;
  inventoryLoading: boolean;
  query: any;
  setOpenInventoryFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TableFieldFilters({
  variant,
  inventoryLoading,
  query,
  setOpenInventoryFilter,
}: Readonly<ITableFieldsFilter>) {
  const filterRef = useRef<any>(null);
  const { RangePicker } = DatePicker;
  const locale = storage.getLocale();
  const localizedText = getLocalizedText(locale);
  const navigate = useNavigate();
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("loan-status");
  const { t: t2 } = useTranslation("component");
  const location = useLocation();

  const [displayFilter, setDisplayFilter] = useState(false);

  useEffect(() => {
    window.onclick = (event: any) => {
      if (
        event.target.contains(filterRef.current) &&
        event.target !== filterRef.current
      ) {
        setDisplayFilter(false);
      }
    };
  }, []);

  const handleDisplayFilter = () => {
    setDisplayFilter((prev) => !prev);
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        ...(["dealer-financing"].includes(variant)
          ? { search: value }
          : { query: value }),
      })}`
    );
  };

  const handleDateFilter = (dateString: any) => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        from_date: dateString
          ? moment(dateString[0].$d).format("YYYY-MM-DD")
          : "",
        to_date: dateString
          ? moment(dateString[1].$d).format("YYYY-MM-DD")
          : "",
      })}`
    );
  };

  const handleDealerFinancingDateFilter = (
    dateString: any[] | string | null
  ): void => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        start_date: dateString
          ? moment(dateString[0].$d).format("YYYY-MM-DD")
          : "",
        end_date: dateString
          ? moment(dateString[1].$d).format("YYYY-MM-DD")
          : "",
      })}`
    );
  };

  return (
    <div>
      <div
        className={`flex items-center gap-3 ${
          variant === "others" ? "border-none" : "border-b"
        } border-solid border-[#e5e7eb] px-4 pb-5`}
      >
        <div className="flex-1">
          <div className="flex items-center gap-3">
            <SearchField className="w-full md:w-auto">
              <div className="text-sm text-[#000000] font-semibold mb-1 hidden md:block">
                {["dealer-financing", "others"].includes(variant)
                  ? t1("search-applications")
                  : t("search-inventory")}
              </div>
              <Input
                placeholder={
                  ["dealer-financing", "others"].includes(variant)
                    ? "Search applications"
                    : t("Search car")
                }
                onChange={(val) => handleFilterSearch(val)}
                prefix={
                  <img
                    src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                    alt="search-icon"
                  />
                }
                allowClear
                className="w-full md:w-[270px] xl:w-[320px]"
              />
            </SearchField>
            <DateField className="hidden md:block">
              <div className="text-sm text-[#000000] font-semibold mb-1">
                {t("filter-by-upload-date")}
              </div>
              <RangePicker
                locale={localizedText}
                onChange={
                  ["dealer-financing"].includes(variant)
                    ? handleDealerFinancingDateFilter
                    : handleDateFilter
                }
                allowClear
              />
            </DateField>
          </div>
        </div>
        {variant !== "change-car" && (
          <div>
            <button
              onClick={() => setOpenInventoryFilter(true)}
              className="block md:hidden"
            >
              <img
                src="https://media.autochek.africa/file/publicAssets/filter-lines.svg"
                alt="filter-icon"
              />
            </button>
            <div className="hidden md:block">
              <button
                ref={filterRef}
                onClick={handleDisplayFilter}
                className="text-[#30345E] text-sm border border-solid border-[#30345E] font-semibold rounded-full flex items-center px-10 py-3"
              >
                More filters
              </button>
            </div>
            {displayFilter && (
              <InventoryCustomFilter
                query={query}
                loading={inventoryLoading}
                type="inventory"
                variant={variant}
                t2={t2}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
