import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { device } from "@/utils/device";
import styled from "styled-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";
import { useTranslation } from "react-i18next";
import { CustomFormField } from "@/layouts/application-layouts/styles";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { Button } from "antd";

const SelectDealerHeaderDontainer = styled.div`
  .title {
    text-align: center;
    font-size: 20px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.18px;
    @media ${device.mobileL} {
      margin-top: 1rem;
    }
  }
`;

const SelectDealerFormContainer = styled.div`
  background: #fff;
  margin-top: 48px;
  max-width: 50%;
  margin-inline: auto;
  padding: 24px 24px 56px 24px;
  @media ${device.mobileL} {
    max-width: 100%;
    padding: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    gap: 1rem;
    border-radius: 0px 0px 16px 16px;

    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Financing = () => {
  const user = storage.getUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [getDealers, setGetDealers] = useState<any>([]);
  const [fetchDealers, setFetchDealers] = useState<any>([]);
  const [searchValue, setSearchValue]: any = useState("")
  const { t } = useTranslation("dealer-gamification");
  const { t: t2 } = useTranslation("inventory");

  const handleDealerChange = (e: any, newValue: any) => {
    setSelectedValue(newValue);
  };

  const handleSubmit = () => {
    navigate(`/dealer/financing?dealer_id=${selectedValue.value}`);
    queryClient.invalidateQueries({
      queryKey: ["fetch_dealer_summary"],
    });
  };

  const country = storage.getCountry();

  const { isLoading: fLoanLoading } = useQuery({
    queryKey: ["pending_loan_statuses_1", country, searchValue],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        page_size: "50",
        account_manager_id: user?.partner?.authority?.roles.includes("DEALER_FINANCE_MANAGER") ? "" : user.id,
        search: searchValue
      });
      return client.get(`v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoanStatus = JSON.parse(res?.data);
      setGetDealers(parsedLoanStatus?.result);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const filterDealers = getDealers?.map((dealer: IDealerUser) => {
      return {
        label: `${dealer.name}`,
        value: dealer?.id,
      };
    });
    setFetchDealers(filterDealers);
  }, [getDealers]);

  return (
    <main>
      <div>
        <div className="flex items-center justify-between mb-3">
          <div className="font-outfitBold custom-font-bold font-bold text-xl lg:text-2xl text-[#30345E]">
            {t("financing")}
          </div>
        </div>

        <SelectDealerHeaderDontainer>
          <div className="title">
            <div>{t("select-dealer")}</div>
          </div>
          <div className="form">
            <div className="container"></div>
          </div>
          <div className="next"></div>
        </SelectDealerHeaderDontainer>
        <SelectDealerFormContainer>
          <div className="form">
            <CustomFormField>
              <div className="label">{t2("list-of-dealers")}</div>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  id="filter_loans"
                  disablePortal
                  loading={fLoanLoading}
                  options={fetchDealers}
                  onChange={handleDealerChange}
                  renderInput={(params) => (
                    <TextField onChange={(e) => setSearchValue(e.target.value)} {...params} placeholder={t("select-dealer")} />
                  )}
                />
              </FormControl>
            </CustomFormField>
            <CustomFormField>
              <div className="loading-btn-container">
                <Button
                  className="loading-btn"
                  type="primary"
                  onClick={handleSubmit}
                  disabled={selectedValue.length === 0}
                >
                  {t2("proceed")}
                </Button>
              </div>
            </CustomFormField>
          </div>
        </SelectDealerFormContainer>
      </div>
    </main>
  );
};

export default Financing;
