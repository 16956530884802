import {
  ApplicationsIcon,
  ApplicationsWebIcon,
  BenefitsIcon,
  BenefitsWebIcon,
  GaugeIcon,
  HelpIcon,
  HistoryIcon,
  HomeIcon,
  HomeWebIcon,
} from "@/assets/svgs";
import client from "@/lib/axios-client";
import storage from "@/lib/storage";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Loader } from "@/pages/auth/styles/loader";

function Footer() {
  const { id }: any = useParams();
  const role = storage.getRole();
  const user = storage.getUser();
  const country = storage.getCountry();
  const location = useLocation();
  const navigate = useNavigate();
  const isFranchise = storage.utilities.isFranchise();
  const { t } = useTranslation("navigation");
  const isLoanDetailsPath = location.pathname === `/applications/loans/${id}`;
  const [loanInfoById, setLoanInfoById] = useState<any>({});
  const [loanStatus, setLoanStatus] = useState<any>({});
  const isAssumedUser = storage.getAssumedUser();

  useQuery({
    queryKey: ["loans_by_id", country, id],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${id}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setLoanInfoById(parsedLoanDetails);
    },
    enabled: !!isLoanDetailsPath,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const toResumeApplication = () => {
    if (loanInfoById?.productType === "Logbook Finance") {
      navigate(`/applications/create-car-for-cash?loan_id=${id}`);
    } else {
      navigate(
        `/applications/loans?car_id=${loanInfoById?.carId}&loan_id=${id}`
      );
    }
  };

  const toUploadDocuments = () => {
    navigate(`/applications/upload-documents?loan_id=${id}`);
  };

  const { isLoading: loadStatusTrail } = useQuery({
    queryKey: ["status_trail", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v2/origination/${id}/status-trail`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResult = JSON.parse(res?.data);
      setLoanStatus(parsedResult);
    },
    enabled: !!isLoanDetailsPath,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const nextActionAfterDocSubmitted = () => {
    let message;

    switch (country) {
      case "NG":
        message =
          "Your documents has been received. After document approval, kindly proceed to upload customs document and request PPI";
        break;
      case "KE":
        message =
          "Your documents has been received. After document approval, kindly proceed to upload valuation and request PPI";
        break;
      default:
        message =
          "Your documents has been received. After document approval, kindly proceed to request PPI";
        break;
    }

    return message;
  };

  const footerNotificationMessage = () => {
    let currentNote;

    switch (loanStatus?.currentStatus) {
      case "APPLICATION_NOT_COMPLETED":
        currentNote = (
          <div className="flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-4">
            Your application is incomplete, kindly resume your application{""}
            <button
              onClick={toResumeApplication}
              disabled={!!isAssumedUser || !!loanInfoById?.salesOppId?.trim()}
              className="text-sm w-fit bg-[#ffb619] font-semibold text-[#30345e] px-4 py-1 rounded-full disabled:text-[#ccc] disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
            >
              Resume application
            </button>
          </div>
        );
        break;
      case "APPLICATION_COMPLETED":
        currentNote =
          "Your application has been submitted, it is currently being reviewed";
        break;
      case "CONDITIONAL_OFFER_RECEIVED":
        currentNote = (
          <div className="flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-4">
            Congratulations, you have received a conditional offer, kindly
            proceed to review your offer(s) and upload documents{""}
            <button
              onClick={toUploadDocuments}
              disabled={!!isAssumedUser || !!loanInfoById?.salesOppId?.trim()}
              className="text-sm w-fit bg-[#ffb619] font-semibold text-[#30345e] px-4 py-1 rounded-full disabled:text-[#ccc] disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
            >
              Upload
            </button>
          </div>
        );
        break;
      case "REFERRED_CONDITIONAL_OFFER":
      case "REJECTED_CONDITIONAL_OFFER":
        currentNote =
          "Your application has been received and it has been referred for further assessment";
        break;
      case "DOCUMENT_SUBMITTED":
        currentNote = nextActionAfterDocSubmitted();
        break;
      case "VALUATION_COMPLETED":
      case "VALUATION_PENDING":
        currentNote =
          "Valuation is in progress, you may proceed to get a final offer when valuation is approved";
        break;
      case "PPI_REQUESTED":
        currentNote = "PPI is requested, kindly proceed to validate the PPI";
        break;
      case "PPI_COMPLETED":
        currentNote = "PPI is completed and approved";
        break;
      case "CUSTOMS_DOCUMENT_UPLOAD":
      case "CUSTOMS_DOCUMENT_APPROVED":
        currentNote = "Customs document upload and approval is in progress";
        break;
      case "FINAL_OFFER_RECEIVED":
        currentNote = (
          <div className="flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-4">
            Congratulations, you have received a final offer, kindly proceed to
            review your offer(s) and upload signed documents
          </div>
        );
        break;
      case "SIGNED_LOAN_DOCUMENTS_SUBMITTED":
        currentNote =
          "You have submitted your signed loan documents for your final offer, kindly proceed to upload equity payment proof";
        break;
      case "PARTIAL_EQUITY_PAID":
        currentNote =
          "Partial equity has been paid. Kindly proceed to complete your equity payments";
        break;
      case "EQUITY_PAID":
        currentNote = "Thank you, your equity payment is completed";
        break;
      default:
        currentNote = t(role?.replace("_", " ")?.toUpperCase());
        break;
    }

    return currentNote;
  };

  const renderFooterNotif = () => {
    if (loadStatusTrail) {
      return <Loader />;
    } else {
      return (
        <div className="flex gap-2 items-start md:items-center">
          <InfoCircleOutlined style={{ fontSize: 20 }} />
          {footerNotificationMessage()}
        </div>
      );
    }
  };

  const icons = {
    home: (
      <HomeIcon
        color={
          location.pathname === "/dealer/dashboard" ? "#30345E" : "#7F83A8"
        }
      />
    ),
    homeWeb: (
      <HomeWebIcon
        color={
          location.pathname === "/dealer/dashboard" ? "#30345E" : "#7F83A8"
        }
      />
    ),
    application: (
      <ApplicationsIcon
        color={
          location.pathname === "/dealer/application" ? "#30345E" : "#7F83A8"
        }
      />
    ),
    applicationWeb: (
      <ApplicationsWebIcon
        color={
          location.pathname === "/dealer/application" ? "#30345E" : "#7F83A8"
        }
      />
    ),
    benefit: (
      <BenefitsIcon
        color={location.pathname === "/dealer/benefit" ? "#30345E" : "#7F83A8"}
      />
    ),
    benefitWeb: (
      <BenefitsWebIcon
        color={location.pathname === "/dealer/benefit" ? "#30345E" : "#7F83A8"}
      />
    ),
    history: (
      <HistoryIcon
        color={location.pathname === "/dealer/history" ? "#30345E" : "#7F83A8"}
      />
    ),
    help: (
      <HelpIcon
        color={location.pathname === "/dealer/help" ? "#30345E" : "#7F83A8"}
      />
    ),
    performance: (
      <GaugeIcon
        color={
          location.pathname === "/dealer/performance" ? "#30345E" : "#7F83A8"
        }
      />
    ),
  };

  const footerMobileMenu = [
    {
      path: "/dealer/dashboard",
      title: "Home",
      icon: icons.home,
    },
    {
      path: "/dealer/applications",
      title: "Applications",
      icon: icons.application,
    },
    {
      path: "/dealer/financing",
      title: "Financing",
      icon: icons.benefit,
    },
    {
      path: "/dealer/history",
      title: "History",
      icon: icons.history,
    },
    {
      path: "/dealer/help",
      title: "Get help",
      icon: icons.help,
    },
  ];

  const menus = footerMobileMenu;

  const userRoles = user?.partner?.authority?.roles;
  const containsDealerFinanceManager = userRoles?.includes(
    "DEALER_FINANCE_MANAGER"
  );
  const doesNotContainAccountManager = !userRoles?.includes("ACCOUNT_MANAGER");

  return (
    <div>
      {isFranchise ? (
        <div className="bg-white z-[1000] shadow-[0px_0px_9.13107681274414px_0px_#00000014] fixed bottom-0 left-0 right-0 md:bottom-[24px] py-3 px-6 md:px-6 w-full md:w-fit md:mx-auto md:my-0 md:rounded-[40px] block sm:hidden">
          <div className="flex justify-between md:justify-center md:gap-10">
            {menus.map((item, index) => (
              <NavLink
                key={menus.indexOf(item)}
                className={({ isActive }) =>
                  isActive ? "text-[#30345E]" : "text-[#7F83A8]"
                }
                to={item.path}
              >
                <div className="flex flex-col items-center">
                  <div className="cursor-pointer">{item.icon}</div>
                  <div className="text-xs md:text-[15px] font-medium">
                    {t(item.title)}
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{ backgroundColor: "#30345E", zIndex: 1005 }}
          className="flex w-full text-sm md:text-base min-h-[56px] h-auto fixed bottom-0 left-0 right-0 py-4 px-3 justify-center items-center text-white font-bold"
        >
          {isLoanDetailsPath
            ? renderFooterNotif()
            : containsDealerFinanceManager && doesNotContainAccountManager
            ? t("DEALER_FINANCE_MANAGER")
            : t(role?.replace("_", " ")?.toUpperCase())}
        </div>
      )}
    </div>
  );
}

export default Footer;
