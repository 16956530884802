import React, { useEffect, useState } from "react";
import styled from "styled-components";
import storage from "@l/storage";
import DealerLevelCard, { IDealerType } from "@/components/DealerLevelCard";
import { device } from "@/utils/device";
import tw from "tailwind-styled-components";
import { Modal, message } from "antd";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import DealerName from "@/components/DealerName";
import DealerHomeBenefits from "@/components/DealerHomeBenefits";
import DealerPerformance from "@/components/DealerPerformance";
import DealerHomeFinancing from "@/components/DealerHomeFinancing";
import DealerLevelModal from "@/components/refix/DealerLevelModal";
import NewDealerBenefits from "@/layouts/dealers-layouts/NewDealerBenefits";
import BenefitEmptyState from "@/components/BenefitEmptyState";
import DealerHistoryView from "@/components/refix/DealerHistoryView";
import DealerLevelRelapseModal from "@/components/refix/DealerLevelRelapseModal";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import {
  IBenefitList,
  IBenefitListResponse,
  IDealerLimit,
  IDealerSummary,
} from "@/interface/dealer";
import { getOrdinal } from "@/utils/helpers";
import { useDealerBenefits, useDealerSummary } from "@/states/dealer";
import NoLoanLimitCard from "@/components/NoLoanLimitCard";
import LoanLimitCard, {
  DealerLimitLoadingCard,
} from "@/components/LoanLimitCard";
import {
  DEALER_FINANCE_COUNTRIES,
  LIMIT_REQUEST_STATUS,
} from "@/utils/finance-status";
import { NewDealerHomepage } from "@/components/home/NewDealerHomepage";

const FirstContainer = tw.div`w-full max-w-full lg:max-w-[33.3%]`;
const SecondContainer = tw.div`w-full max-w-full lg:max-w-[66.6%] flex flex-col gap-3`;

const DealerLayout = styled.div`
  background: transparent;
  border: none;
  border-radius: 4px;
  min-height: 100vh;
  height: auto;
  .custom-scroll-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    @media ${device.mobileL} {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    @media ${device.tablet} {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;
    }
    .custom-card {
      flex: 0 0 auto;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default function DealerDashboard({
  myHistory,
  setMyHistory,
  toViewHistory,
}: any) {
  const role = storage.getRole();
  const user = storage.getUser();
  const country = storage.getCountry();
  const [relapseOpen, setRelapseOpen] = useState<boolean>();
  const [dealerLevelOpen, setDealerLevelOpen] = useState<boolean>();
  const [dealerSummary, setDealerSummary] = useState<IDealerSummary | null>();
  const [benefitList, setBenefitList] = useState<IBenefitList[]>([]);
  const [dealerLimit, setDealerLimit] = useState<IDealerLimit | undefined>();

  const STATUSES = [
    LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED,
    LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW,
    LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED,
    LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED,
  ];

  useEffect(() => {
    document.title = `Dealer Plus | Dealer Dashboard [${role}]`;
    setRelapseOpen(false);
    setDealerLevelOpen(false);
  }, [role]);

  const dealerBenefitStore = useDealerBenefits.getState();
  const dealerSummaryStore = useDealerSummary.getState();

  const { isLoading: summaryLoading, isError: isDealerError } = useQuery({
    queryKey: ["fetch_dealer_summary"],
    queryFn: () => {
      const franchiseId = user.company.id;

      return client.get(`/v1/franchise/${franchiseId}/dealer-tiers/summary`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerSummary = JSON.parse(res?.data);
      setDealerSummary(parsedResponseData);
      if (parsedResponseData?.isNewLevel) {
        setDealerLevelOpen(true);
        setRelapseOpen(true);
      }
      dealerSummaryStore.setSummary(parsedResponseData);
    },
    onError: (err: any) => {
      const parsedErr = JSON.parse(err.response.data);
      message.error(parsedErr.message ? parsedErr.message : parsedErr.error);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  //dealerSummary?.level.toLowerCase() === "non originating"

  const isNonOriginatingDealer =
    dealerSummary?.level.toLowerCase() === "non originating" || isDealerError;

  const { isLoading: benefitsLoading } = useQuery({
    queryKey: ["fetch_benefit_list"],
    queryFn: () => {
      return client.get(`/v1/franchise/dealer-tiers/benefits`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IBenefitListResponse = JSON.parse(res?.data);
      setBenefitList(parsedResponseData.data);
      dealerBenefitStore.setBenefits(parsedResponseData.data);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isFetching: dealerLimitFetch } = useQuery({
    queryKey: ["fetch_dashboard_dealer_limit"],
    queryFn: () => {
      return client.get(`/v1/dealer/${user?.company?.id}`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerLimit = JSON.parse(res?.data);
      setDealerLimit(parsedResponseData);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const renderDealerLimitCard = () => {
    if (dealerLimitFetch) {
      return <DealerLimitLoadingCard />;
    }

    if (isNonOriginatingDealer || dealerLimit?.results?.length === 0) {
      return <NoLoanLimitCard dealerLimit={undefined} limitArray={[]} />;
    }

    return (
      <div className="flex flex-col gap-3">
        {dealerLimit?.results?.map((limit: any) => (
          <div key={limit?.id}>
            {STATUSES.includes(limit?.status as LIMIT_REQUEST_STATUS) ? (
              <LoanLimitCard dealerLimit={limit} loading={dealerLimitFetch} />
            ) : (
              <NoLoanLimitCard
                dealerLimit={limit}
                limitArray={dealerLimit?.results}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={myHistory}
        onCancel={() => setMyHistory(false)}
        footer={null}
        width={900}
      >
        <DealerHistoryView
          dealerType={dealerSummary?.level!}
          dealerName={dealerSummary?.dealership!}
          closeModal={() => setMyHistory(false)}
        />
      </Modal>

      {dealerSummary && (
        <>
          {relapseOpen && dealerSummary?.levelUpgradeType === "downgrade" && (
            <DealerLevelRelapseModal
              close={() => setRelapseOpen(false)}
              prevLevel={dealerSummary.levelUpgrade}
              currentLevel={dealerSummary.level}
            />
          )}

          {dealerLevelOpen && dealerSummary?.levelUpgradeType === "upgrade" && (
            <DealerLevelModal
              level={dealerSummary.level.toLowerCase()}
              closeModal={() => setDealerLevelOpen(false)}
            />
          )}
        </>
      )}

      <DealerLayout className="flex flex-col gap-4 sm:px-0">
        <NewDealerHomepage
          dealerType={dealerSummary?.level!}
          loading={benefitsLoading}
        />
        {/* <DealerName
          props={{
            dealerName: dealerSummary?.dealership!,
            activeDate: dealerSummary?.onboardingDate!,
            id: dealerSummary?.id!,
          }}
        /> */}

        {/* <div className="flex flex-col gap-5">
          <div className="flex flex-col lg:flex-row lg:items-start gap-5 sm:gap-4">
            <FirstContainer>
              <DealerLevelCard
                type={
                  dealerSummary?.level.toLowerCase() === "non originating"
                    ? "new-dealer"
                    : (dealerSummary?.level.toLowerCase() as IDealerType)
                }
                position={getOrdinal(dealerSummary?.position! ?? 0)}
                total={dealerSummary?.totalPosition!}
                potentialPoints={dealerSummary?.potentialPoints! ?? 0}
                lostPoints={dealerSummary?.lostPoints! ?? 0}
                totalPoints={dealerSummary?.points! ?? 0}
                viewHistory={toViewHistory}
                level={dealerSummary?.level!}
                loading={summaryLoading}
                useDefault={isDealerError}
              />
            </FirstContainer>
            <SecondContainer>
              {isNonOriginatingDealer ? (
                <BenefitEmptyState />
              ) : (
                <>
                  {DEALER_FINANCE_COUNTRIES.includes(country) && (
                    <>{renderDealerLimitCard()}</>
                  )}
                  <DealerHomeBenefits
                    benefits={dealerSummary?.benefits!}
                    allBenefits={benefitList}
                    dealerType={dealerSummary?.level!}
                    loading={benefitsLoading}
                  />
                </>
              )}
            </SecondContainer>
          </div>

          {isNonOriginatingDealer ? (
            <NewDealerBenefits />
          ) : (
            <DealerPerformance
              commission={dealerSummary?.commissions!}
              commissionPerc={dealerSummary?.commissionsPerc!}
              revenue={dealerSummary?.revenue!}
              revenuePerc={dealerSummary?.revenuePerc!}
              conversionRate={dealerSummary?.conversionRate!}
              conversionRatePerc={dealerSummary?.conversionRatePerc!}
              bestSeller={dealerSummary?.bestSeller!}
              isLoading={summaryLoading}
            />
          )}

          <DealerHomeFinancing />
        </div> */}
      </DealerLayout>

      <NewLoansBtn />
    </div>
  );
}
